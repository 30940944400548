<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
      <b-col md="6">
        <b-card no-body>
            <b-row class="card-body">
            <b-col cols="12">
              <b-form-group
                :label="$t('Id')"
                label-for="h-id"
                label-cols-md="4"
              >
                {{subscription.id}}
              </b-form-group>
            </b-col>
             <b-col cols="12">
               <b-form-group
                 :label="$t('Package Name')"
                 label-for="h-package_name"
                 label-cols-md="4"
               >
                   {{subscription.package_name}}
               </b-form-group>
             </b-col>
             <b-col cols="12">
               <b-form-group
                 :label="$t('Period')"
                 label-for="h-period"
                 label-cols-md="4"
               >
                   {{subscription.period}} {{$t('Months')}}
               </b-form-group>
             </b-col>
             <b-col cols="12">
               <b-form-group
                 :label="$t('Price')"
                 label-for="h-price"
                 label-cols-md="4"
               >
                   {{subscription.price}}
                   <small>{{$t('SAR')}}</small>
               </b-form-group>
             </b-col>
             <b-col cols="12">
               <b-form-group
                 :label="$t('Start Date')"
                 label-for="h-start_date"
                 label-cols-md="4"
               >
                   {{subscription.start_date | formatDateWithoutHours}}
               </b-form-group>
             </b-col>
             <b-col cols="12">
               <b-form-group
                 :label="$t('End Date')"
                 label-for="h-end_date"
                 label-cols-md="4"
               >
                   {{subscription.end_date | formatDateWithoutHours}}
               </b-form-group>
             </b-col>
             <b-col cols="12" class="subscription-badges">
               <b-form-group
                 :label="$t('Status')"
                 label-for="h-status"
                 label-cols-md="4"
               >
                   <template v-if="subscription.status == 'pending'">
                     <b-badge class="d-block ml-1" variant="light-warning">
                       {{$t('Pending')}}
                     </b-badge>
                   </template>
                   <template v-else-if="subscription.status == 'active'">
                     <b-badge class="d-block ml-1" variant="light-success">
                       {{$t('Active')}}
                     </b-badge>
                   </template>
                   <template v-else-if="subscription.status == 'expired'">
                     <b-badge class="d-block ml-1" variant="light-secondary">
                       {{$t('Expired')}}
                     </b-badge>
                   </template>
                   <template v-else>
                     <b-badge class="d-block ml-1" variant="light-danger">
                       {{$t('Canceled')}}
                     </b-badge>
                   </template>
               </b-form-group>
             </b-col>
           </b-row>
        </b-card>
      </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  name: 'SubscriptionShow',
  data() {
    return {
      subscription: {},
      isLoading: false,
    }
  },
  mounted() {
    this.getSubscription();
  },
  methods: {
    getSubscription(page = 1){
      this.isLoading = true
      useJwt.post('/subscriptions/get-by-id',{id: this.$route.params.id})
      .then((response) => {
        this.subscription = response.data.data
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    }
  }
}
</script>
